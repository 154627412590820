import { Component, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { ApisService } from 'app/services/apis.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public user: FormGroup;

  constructor(private router: Router, public api: ApisService, private spinner: NgxSpinnerService) { }
  submitted = false;
  ngOnInit() {
    let token = localStorage.getItem('isLogin');

    if(!token){
      this.router.navigate(['/login']);
    }else{
      this.router.navigate(['/dashboard/2024']);
    }
    this.user = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  get f() { return this.user.controls; }

  public hasError = (controlName: string, errorName: string) => {
    return this.user.controls[controlName].hasError(errorName);
  }

  fn_login(userValue) {
    this.submitted = true;
    if (this.user.invalid) {
      return;
    }
    this.spinner.show();
    this.api.postFormData('api/login', userValue).then((data: any) => {

      //console.log("data", data['data'][0].role); return;
      this.spinner.hide();
      if (data['success']) {
        localStorage.setItem("userInfo", JSON.stringify(data['data']));
        localStorage.setItem("isLogin", data['token']);
        console.log("data['data']",data['data']); 
        if(data['data'][0].role == 1){
          this.router.navigate(['/dashboard/2024']);
        }else if(data['data'][0].role == 2){
          this.router.navigate(['/order/1/1']);
        }else{
          this.router.navigate(['/dashboard-partner']);
        }
        
      }else{
        Swal.fire('Oops...', data['message'], 'error')  
      }
    }, error => {
      this.spinner.hide();
      Swal.fire('Oops...', 'Email or password are wrong!', 'error')
    });
  }
}
